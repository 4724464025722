//#region Font Sizes
$default-font-size: 1.4rem;
$font-size-fourteen: $default-font-size * 9.313;
$font-size-thirteen: $default-font-size * 7.451;
$font-size-twelve: $default-font-size * 5.96;
$font-size-eleven: $default-font-size * 4.768;
$font-size-ten: $default-font-size * 3.815;
$font-size-nine: $default-font-size * 3.051875;
$font-size-eight: $default-font-size * 2.44125;
$font-size-seven: $default-font-size * 1.953125;
$font-size-six: $default-font-size * 1.5625;
$font-size-five: $default-font-size * 1.25;
$font-size-four: $default-font-size * 1;
$font-size-three: $default-font-size * .8;
$font-size-two: $default-font-size * .64;
$font-size-one: $default-font-size * .511875;
//#endregion

//#region Media Query Breakpoints
$nav-list-break-max-width: 23.125em;
$small-font-max-width: 29.3125em;
$nav-break-max-width: 35.0625em;
$phone-max-width: 40.9375em;
$tab-port-max-width: 52.6875em;
$tab-land-max-width: 58.5625em;
$nav-switch-width: 68.75em;
$larger-than-nav-switch: calc(#{$nav-switch-width} + 0.001em);
$big-desktop-min-width: 112.5em;
//#endregion

//#region Scrollbar 
$scrollbar-box-shadow: inset 0 0 $default-font-size * 0.5
	rgba(var(--color-primary-1-rgb), 0.5);
$scrollbar-track-background: var(--color-primary-1);
$scrollbar-thumb-background: var(--color-primary-4);
//#endregion

//#region Fonts
// $font-family-text: "Roboto", sans-serif;
// $font-family-headers: "Cinzel", sans-serif;
$line-height: 1.5;
$font-family-fancy-bridge: "New Tegomin", serif;
$font-family-text: "Open Sans", sans-serif;
$font-family-headers: "Merriweather", serif;
//#endregion

//#region Sizes
$scale-amount: 1.05;
$navbar__logo-width: $font-size-eleven;
$github__logo-width: $navbar__logo-width;
$navbar__logo-width-nav-switch:  $font-size-eleven;
$content-padding-nav-switch: 5vmin;
$paddingFactor: 1.277;
//#endregion

//#region Navbar
$navbar-full-z-index: 5;
$navbar__button-bar-before-rotation: rotate(105deg);
$navbar__button-bar-after-rotation: rotate(-105deg);
$navbar-width-nav-switch: 97.5vw;

$navbar__item-margin-top: $default-font-size * 0.25;
$navbar__item-border-radius: $default-font-size * 10;
$navbar__item-divider-width: $default-font-size * 0.2;
$navbar__item-divider: $navbar__item-divider-width solid var(--color-primary-1);

$navbar-box-shadow-resume-color: rgba($color-black, 0.15);
$navbar-box-shadow-resume: 0 $default-font-size $default-font-size * 2
	rgba($color-black, 0.33);
$navbar-box-shadow-normal: 0px 4px 8px rgba($color-black, 0.33);
$navbar-box-shadow-left-right-hover: -10px 0 10px rgba($color-black, 0.5);
$navbar-box-shadow-hover: 0px 10px 10px rgba($color-black, 0.5);
$hover-color-change-amount: 0.15;
//#endregion

//#region Skills
$skills-linear-gradient-original: linear-gradient(
	to left,
	var(--color-primary-1) 0%,
	var(--color-primary-4) 5%,
	var(--color-primary-1) 10%,
	var(--color-primary-1) 100%
);
$skills-linear-gradient-hover: linear-gradient(
	to right,
	var(--color-primary-1),
	var(--color-primary-1)
);
$skill-popup-grid-column-template:  max-content 2fr min-content min-content min-content;
$skills-popup-padding-amount: $default-font-size * 2;
//#endregion

//#region Home
$home-background-color-opacity: 0.1;
$home-page-border: 2px solid rgba(var(--color-primary-4-rgb), 0.5);
$home-page-animation: 1.5s 1 translateToZero forwards;
$breakpoint-to-change-layout: "tab-land";
$home-name-font-weight: 100;
$home-regular-text-opacity: 0.85;
$home-content-header-gradient-colors: var(--color-primary-4),
	var(--color-primary-4) var(--primary-4-end), var(--background-image-color-two);
//#endregion

//#region Resume
$resume-section-padding-top: $default-font-size * 2;
$resume-card-width-full: 600px;
$resume-header-text-shadow: getTextShadow(
	close,
	var(--color-primary-3-rgb),
	var(--color-primary-2-rgb)
);
//#endregion

//#region Header
$header-top:  $default-font-size * 1.5;
$page-margin-top-amount: calc(#{$header-top});
$page-margin-top-amount-nav-switch: $default-font-size * 7.5;
$header-height: $default-font-size * 6.25;
$header-toggler-width: 4rem;
$header-inner-width: calc(
	#{$resume-card-width-full} + ((100% - #{$resume-card-width-full}) / 2) + #{$navbar__logo-width}
);
$header-inner-width-vw: calc(
	#{$resume-card-width-full} + ((100vw - #{$resume-card-width-full}) / 2) + #{$navbar__logo-width}
);
$header-width-nav-switch: calc(100vw - #{$default-font-size * 1.33});
// $header-width-tab-land: calc(100vw - #{$default-font-size * 2});
// $header-width-phone: calc(100vw - #{$default-font-size * 2});
// $header-width-small-font: calc(100vw - #{$default-font-size * 2});

//#region animation
$animation-duration: 0.5s;
$animation-easing-standard: ease;
$animation-easing-standard-easeInOutBack: cubic-bezier(0.68, -0.6, 0.32, 1.6);
$animation-easing-standard-easeInOutBack-smaller: cubic-bezier(
	0.68,
	-0.2,
	0.32,
	1.2
);
$animation-easing-flash: cubic-bezier(0.68, -2, 0.32, 1);
$animation-easing-modern: cubic-bezier(1, 0, 0, 1);
$animation-navbar: $animation-duration $animation-easing-standard;
$animation-bridge-width: 1px;
// $animation-bridge-width: calc((100% - #{$header-inner-width-vw}) + #{$navbar__logo-width});
$animation-bridge-windshield-start: polygon(
	$animation-bridge-width 100%,
	$animation-bridge-width 0,
	100% 100%,
	100% 0%
);
$animation-bridge-windshield-end: polygon(
	$animation-bridge-width 100%,
	100% 100%,
	$animation-bridge-width 0,
	100% 0%
);

$animation-bridge-windshield-two-start: polygon(
	$animation-bridge-width 100%,
	$animation-bridge-width 0,
	calc(#{$animation-bridge-width} + 1px) 1000%,
	200% 100%,
	100% 0%,
	$animation-bridge-width 0
);
$animation-bridge-windshield-two-end: polygon(
	$animation-bridge-width 100%,
	$animation-bridge-width 0,
	100% 1px,
	200% 100%,
	100% 0%,
	$animation-bridge-width 0
);
//#endregion

//#region misc
$bg-video-opacity: 0.033;
//#endregion

//#region bridge
$bridge-card-section-1-gradient: linear-gradient(
	#{var(--bridge-gradient-direction)},
	rgba(var(--color-primary-1-rgb), 0.5),
	rgba(var(--color-primary-2-rgb), 0.5)
);
$bridge-card-section-2-gradient: linear-gradient(
	#{var(--bridge-gradient-direction)},
	rgba($color-primary-bridge-2, 0.5),
	rgba($color-primary-bridge-3, 0.5)
);
$bridge-card-section-3-gradient: linear-gradient(
	var(--bridge-gradient-direction),
	rgba($color-primary-bridge-3, 0.5),
	rgba($color-primary-bridge-1, 0.5)
);
$bridge-arrow-hover-scale-amount: 1.1;
$bridge-card-hover-button-opacity: 0.5;
$bridge-page-nav-link-scale-amount: 1.1;
$bridge-hero-animation-duration: $animation-duration * 2;
$hero-background-opacity: 0.66;
$arrow-button-position: calc(
	(
			100vw -
				calc(
					#{$resume-card-width-full} + (
							(100vw - #{$resume-card-width-full}) / 2
						) + #{$navbar__logo-width}
				)
		) / 2
);
$bridge-card-box-shadow-size: 0 0rem $default-font-size * 2 (-$default-font-size *
	0.5);
$bridge-card-border-size: 2px solid;
$hero-background-image-alpha: 0.33;
$hero-blend-mode: hard-light;
$bridge-logo-opacity: 0.25;
$card-image-drop-shadow-x: 6px;
$card-image-drop-shadow-y: 4px;
$card-image-drop-shadow-opacity: 0.33;
$card-z-index: 1;
$card-height: $default-font-size * 50;
$card-header-height: $card-height / 3.333333333333;
$card-initial-opacity: 0.25;
$card-pause-and-stop-top: 85%;
$card-pause-and-stop-width: 25%;
//#endregion

//carousel
$carousel-item-card-size-nav-switch: $default-font-size * 12;
$carousel-full-screen-item-height: 87.5vh;
$carousel-fullscreen-button-hover-opacity: 1;
$carousel-progress-bar-height: 2.5vh;
$carousel-full-screen-media-height: calc(
	#{$carousel-full-screen-item-height} - #{$carousel-progress-bar-height}
);
$carousel-width: 50vw;
$carousel-svg-width: $default-font-size * 6;
$carousel-svg-parent-width: calc(100vh - #{$carousel-full-screen-item-height});
$carousel-svg-parent-width-nav-switch: calc(
	#{$carousel-svg-parent-width} / 1.5
);
$carousel-svg-parent-offset-nav-switch: calc(
	50% - #{$carousel-svg-parent-width-nav-switch}
);
$carousel-column-gap: $default-font-size;
$carousel-items-per-row: 4;
$carousel-grid-width: calc(
	(#{$resume-card-width-full} - 2 * #{$carousel-column-gap * $carousel-items-per-row}) / #{$carousel-items-per-row} + .3rem
);
$carousel-nav-switch-button-bottom: calc(100vh - #{$carousel-full-screen-media-height});
//#endregion

//#region Page Wrapper
$page-wrapper-padding-top-spacing: calc(#{$header-top} + #{$default-font-size});
//#endregion

//#region PageNav
$page-nav-top: calc(#{$navbar__logo-width} + #{$default-font-size} * 1);
//#endregion

//NOTE: any new variables that you want to reference in React have to manually added to section for custom props in constants.ts
:root {
	scroll-behavior: smooth;
	scroll-padding-top: $header-height * 1.2;
	--scrollbar-width: #{$default-font-size * 1};
	--default-font-size: #{$default-font-size};
	--page-nav-min-column-width: calc(#{$default-font-size} * 9.5375);
	--site-nav-button-width: 7.5vw;
	--site-nav-item-gradient-direction: 45deg;
	--site-nav-linear-gradient: linear-gradient(
		to right,
		#{var(--color-primary-1)} 0%,
		#{var(--color-primary-1)} 0%,
		#{var(--color-primary-4)} 0%,
		#{var(--color-primary-4)} 100%
	);
	--site-nav-active-scale-amount: 1;
	--body-background: linear-gradient(
		to left,
		var(--color-primary-1),
		var(--color-primary-1)
	);


	//setting media query custom properties
	--small-font-max-width: #{$small-font-max-width};
	--nav-break-max-width: #{$nav-break-max-width};
	--phone-max-width: #{$phone-max-width};
	--tab-port-max-width: #{$tab-port-max-width};
	--tab-land-max-width: #{$tab-land-max-width};
	--big-desktop-min-width: #{$big-desktop-min-width};
	--nav-switch-width: #{$nav-switch-width};
	--larger-than-nav-switch: #{$larger-than-nav-switch};

	//misc
	--header-toggler-svg-rotation: 180deg;
	--header-toggler-translate-x: 0;
	--header-toggler-translate-y: 0;
	--header-height: #{$header-height};
	--header-toggler-height: #{$header-height + $header-toggler-width};
	--header-toggler-width: #{$header-toggler-width};
	--header-inner-width-nav-switch: 100%;
	--navbar-logo-width: #{$navbar__logo-width};
	--page-padding-top: var(--header-height);

	//bridge page
	--bridge-gradient-direction: to right;
	@include respond(nav-switch) {
		--bridge-gradient-direction: to bottom;
	}
	
	--bridge-arrow-button-fill-hover-nav-switch: var(--color-primary-bridge-4);
	--bridge-arrow-button-fill-hover: var(--color-primary-bridge-2);
	--bridge-arrow-button-fill-left: var(--color-primary-bridge-1);
	--bridge-arrow-button-fill-right: var(--color-primary-bridge-1);
	--bridge-arrow-button-left-fill-hover: var(--color-primary-bridge-2);
	--bridge-arrow-button-right-fill-hover: var(--color-primary-bridge-2);
	--bridge-arrow-button-rotation: 90deg;
	--bridge-arrow-hover-scale-amount: 1.1;
	--bridge-hero-rotate-heading-1: 0deg;
	--bridge-hero-rotate-heading-2: 0deg;
	--bridge-hero-rotate-heading-3: 0deg;
	--bridge-hero-rotate-heading-4: 0deg;
	--bridge-hero-translate-heading-1: (-50%, calc(-50% - 50vh));
	--bridge-hero-translate-heading-2: (calc(-50% - 50vw), -50%);
	--bridge-hero-translate-heading-3: (calc(50% + 50vw), -50%);
	--bridge-hero-translate-heading-4: (-50%, calc(50% + 50vh));
	--bridge-link-svg-fill: var(--color-primary-bridge-4);
	--bridge-link-svg-fill-hover: var(--color-primary-bridge-1);
	--bridge-link-text-fill: var(--color-primary-bridge-1);
	--bridge-link-text-fill-hover: var(--color-primary-bridge-4);
	--bridge-page-nav-link-color-hover: var(--color-primary-1);
	--bridge-page-nav-link-color: var(--color-primary-4);
	--bridge-section-height: 0px;
	--bridge-section-padding: 0;
	--card-playing-transform: translate3d(0, 0, 0) scale(1);
	--card-scale-amount: 1;
	--hero-more-y-translate: -50%;

	//csharp layout pages
	--carousel-grid-width: #{$carousel-grid-width};

	//shapes stuff
	--diamond-fractal-animation-duration: 120s;
	--diamond-fractal-length: calc(20vw * var(--aspect-ratio-factor));
	--aspect-ratio-factor: calc(16 / 9);
	--diamond-fractal-rotation: 45deg;
	--translate-center: translate3d(-50%, -50%, 0);
	--square-root-of-two: 0.707106781;
	--diamond-outer-opacity: 0.05;

	//audio player
	--audio-player-toggler-rotation-closed: 270deg;
	--audio-player-toggler-rotation-open: 90deg;
}
