@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ------------------------------------------------------------------------------------------------- */
:root {
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f3f1f1;
  --color-grey-light-3: #f1efef;
  --color-grey-light-1-rgb: 250, 249, 249;
  --color-grey-light-2-rgb: 243, 241, 241;
  --color-grey-light-3-rgb: 241, 239, 239;
  --color-black: #111111;
  --color-grey-20: #333333;
  --color-grey-40: #666666;
  --color-grey-60: #999999;
  --color-grey-80: #cccccc;
  --color-white: #ffffff;
  --color-black-rgb: 17, 17, 17;
  --color-grey-20-rgb: 51, 51, 51;
  --color-grey-40-rgb: 102, 102, 102;
  --color-grey-60-rgb: 153, 153, 153;
  --color-grey-80-rgb: 204, 204, 204;
  --color-white-rgb: 255, 255, 255;
  --color-primary-1: #28537b;
  --color-primary-1-light: #999999;
  --color-primary-2: #8ac6d0;
  --color-primary-3: #f4d262;
  --color-primary-4: #fbeeac;
  --color-primary-red: #7B2853;
  --color-primary-1-rgb: 40, 83, 123;
  --color-primary-1-light-rgb: 153, 153, 153;
  --color-primary-2-rgb: 138, 198, 208;
  --color-primary-3-rgb: 244, 210, 98;
  --color-primary-4-rgb: 251, 238, 172;
  --color-primary-red-rgb: 123, 40, 83;
  --color-primary-resume-1: #28537b;
  --color-primary-resume-2: #8ac5d0;
  --color-primary-resume-3: #f4d262;
  --color-primary-resume-4: #fbeeac;
  --color-primary-resume-red: #7B2853;
  --color-primary-resume-1-rgb: 40, 83, 123;
  --color-primary-resume-2-rgb: 138, 197, 208;
  --color-primary-resume-3-rgb: 244, 210, 98;
  --color-primary-resume-4-rgb: 251, 238, 172;
  --color-primary-resume-red-rgb: 123, 40, 83;
  --color-primary-bridge-2: #f59229;
  --color-primary-bridge-4: #f5f1db;
  --color-primary-bridge-3: #e4e0c9;
  --color-primary-bridge-1: #00406b;
  --color-primary-bridge-red: #6b0700;
  --color-primary-bridge-2-rgb: 245, 146, 41;
  --color-primary-bridge-4-rgb: 245, 241, 219;
  --color-primary-bridge-3-rgb: 228, 224, 201;
  --color-primary-bridge-1-rgb: 0, 64, 107;
  --color-primary-bridge-red-rgb: 107, 7, 0;
  --color-primary-downloader-1: #f9f7f7;
  --color-primary-downloader-2: #dbe2ef;
  --color-primary-downloader-3: #3f72af;
  --color-primary-downloader-4: #112d4e;
  --color-primary-downloader-red: #b05840;
  --color-primary-downloader-1-rgb: 249, 247, 247;
  --color-primary-downloader-2-rgb: 219, 226, 239;
  --color-primary-downloader-3-rgb: 63, 114, 175;
  --color-primary-downloader-4-rgb: 17, 45, 78;
  --color-primary-downloader-red-rgb: 176, 88, 64;
  --color-primary-playlist-syncer-1: #ffc996;
  --color-primary-playlist-syncer-3: #9f5f80;
  --color-primary-playlist-syncer-2: #ff8474;
  --color-primary-playlist-syncer-4: #583d72;
  --color-primary-playlist-syncer-red: #ff8474;
  --color-primary-playlist-syncer-1-rgb: 255, 201, 150;
  --color-primary-playlist-syncer-3-rgb: 159, 95, 128;
  --color-primary-playlist-syncer-2-rgb: 255, 132, 116;
  --color-primary-playlist-syncer-4-rgb: 88, 61, 114;
  --color-primary-playlist-syncer-red-rgb: 255, 132, 116;
  --color-primary-autobid-1: #e6e6e6;
  --color-primary-autobid-3: #532e1c;
  --color-primary-autobid-2: #c5a880;
  --color-primary-autobid-4: #0f0f0f;
  --color-primary-autobid-red: #541c2f;
  --color-primary-autobid-1-rgb: 230, 230, 230;
  --color-primary-autobid-3-rgb: 83, 46, 28;
  --color-primary-autobid-2-rgb: 197, 168, 128;
  --color-primary-autobid-4-rgb: 15, 15, 15;
  --color-primary-autobid-red-rgb: 84, 28, 47;
  --color-primary-replay-1: #28537b;
  --color-primary-replay-2: #8ac6d0;
  --color-primary-replay-3: #f4d262;
  --color-primary-replay-4: #fbeeac;
  --color-primary-replay-red: #7B5028;
  --color-primary-replay-1-rgb: 40, 83, 123;
  --color-primary-replay-2-rgb: 138, 198, 208;
  --color-primary-replay-3-rgb: 244, 210, 98;
  --color-primary-replay-4-rgb: 251, 238, 172;
  --color-primary-replay-red-rgb: 123, 80, 40;
  --color-primary-about-1: #161616;
  --color-primary-about-2: #c84b31;
  --color-primary-about-3: #31C9B0;
  --color-primary-about-4: #ecdbba;
  --color-primary-about-red: #c84b31;
  --color-primary-about-1-rgb: 22, 22, 22;
  --color-primary-about-2-rgb: 200, 75, 49;
  --color-primary-about-3-rgb: 49, 201, 176;
  --color-primary-about-4-rgb: 236, 219, 186;
  --color-primary-about-red-rgb: 200, 75, 49;
  --color-primary-the-big-five-1: #7D5A50;
  --color-primary-the-big-five-2: #B4846C;
  --color-primary-the-big-five-3: #E5B299;
  --color-primary-the-big-five-4: #FCDEC0;
  --color-primary-the-big-five-red: #111111;
  --color-primary-the-big-five-1-rgb: 125, 90, 80;
  --color-primary-the-big-five-2-rgb: 180, 132, 108;
  --color-primary-the-big-five-3-rgb: 229, 178, 153;
  --color-primary-the-big-five-4-rgb: 252, 222, 192;
  --color-primary-the-big-five-red-rgb: 17, 17, 17;
}

:root {
  scroll-behavior: smooth;
  scroll-padding-top: 10.5rem;
  --scrollbar-width: 1.4rem;
  --default-font-size: 1.4rem;
  --page-nav-min-column-width: calc(1.4rem * 9.5375);
  --site-nav-button-width: 7.5vw;
  --site-nav-item-gradient-direction: 45deg;
  --site-nav-linear-gradient: linear-gradient(
  	to right,
  	var(--color-primary-1) 0%,
  	var(--color-primary-1) 0%,
  	var(--color-primary-4) 0%,
  	var(--color-primary-4) 100%
  );
  --site-nav-active-scale-amount: 1;
  --body-background: linear-gradient(
  	to left,
  	var(--color-primary-1),
  	var(--color-primary-1)
  );
  --small-font-max-width: 29.3125em;
  --nav-break-max-width: 35.0625em;
  --phone-max-width: 40.9375em;
  --tab-port-max-width: 52.6875em;
  --tab-land-max-width: 58.5625em;
  --big-desktop-min-width: 112.5em;
  --nav-switch-width: 68.75em;
  --larger-than-nav-switch: calc(68.75em + 0.001em);
  --header-toggler-svg-rotation: 180deg;
  --header-toggler-translate-x: 0;
  --header-toggler-translate-y: 0;
  --header-height: 8.75rem;
  --header-toggler-height: 12.75rem;
  --header-toggler-width: 4rem;
  --header-inner-width-nav-switch: 100%;
  --navbar-logo-width: 6.6752rem;
  --page-padding-top: var(--header-height);
  --bridge-gradient-direction: to right;
  --bridge-arrow-button-fill-hover-nav-switch: var(--color-primary-bridge-4);
  --bridge-arrow-button-fill-hover: var(--color-primary-bridge-2);
  --bridge-arrow-button-fill-left: var(--color-primary-bridge-1);
  --bridge-arrow-button-fill-right: var(--color-primary-bridge-1);
  --bridge-arrow-button-left-fill-hover: var(--color-primary-bridge-2);
  --bridge-arrow-button-right-fill-hover: var(--color-primary-bridge-2);
  --bridge-arrow-button-rotation: 90deg;
  --bridge-arrow-hover-scale-amount: 1.1;
  --bridge-hero-rotate-heading-1: 0deg;
  --bridge-hero-rotate-heading-2: 0deg;
  --bridge-hero-rotate-heading-3: 0deg;
  --bridge-hero-rotate-heading-4: 0deg;
  --bridge-hero-translate-heading-1: (-50%, calc(-50% - 50vh));
  --bridge-hero-translate-heading-2: (calc(-50% - 50vw), -50%);
  --bridge-hero-translate-heading-3: (calc(50% + 50vw), -50%);
  --bridge-hero-translate-heading-4: (-50%, calc(50% + 50vh));
  --bridge-link-svg-fill: var(--color-primary-bridge-4);
  --bridge-link-svg-fill-hover: var(--color-primary-bridge-1);
  --bridge-link-text-fill: var(--color-primary-bridge-1);
  --bridge-link-text-fill-hover: var(--color-primary-bridge-4);
  --bridge-page-nav-link-color-hover: var(--color-primary-1);
  --bridge-page-nav-link-color: var(--color-primary-4);
  --bridge-section-height: 0px;
  --bridge-section-padding: 0;
  --card-playing-transform: translate3d(0, 0, 0) scale(1);
  --card-scale-amount: 1;
  --hero-more-y-translate: -50%;
  --carousel-grid-width: calc((600px - 2 * 5.6rem) / 4 + 0.3rem);
  --diamond-fractal-animation-duration: 120s;
  --diamond-fractal-length: calc(20vw * var(--aspect-ratio-factor));
  --aspect-ratio-factor: calc(16 / 9);
  --diamond-fractal-rotation: 45deg;
  --translate-center: translate3d(-50%, -50%, 0);
  --square-root-of-two: 0.707106781;
  --diamond-outer-opacity: 0.05;
  --audio-player-toggler-rotation-closed: 270deg;
  --audio-player-toggler-rotation-open: 90deg;
}
@media only screen and (max-width: 68.75em) {
  :root {
    --bridge-gradient-direction: to bottom;
  }
}

/* global reset;  box-sizing is set on body and set to inherit on all other elements */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* 62.5% of the browser supplied font-size is usually 16px */
  /* bad practice to declarer px font-size here as it removes users ability to set font-size in browser */
  font-size: 62.5%;
  box-sizing: border-box;
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}
@media only screen and (max-width: 58.5625em) {
  html {
    font-size: 66%;
  }
}
@media only screen and (max-width: 52.6875em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 29.3125em) {
  html {
    font-size: 2.33vmin;
  }
}

/* everything related to font goes in body */
body {
  font-weight: 400;
  line-height: 1.5;
  font-family: "Merriweather", serif;
  color: var(--color-primary-1);
  overflow-x: hidden;
}

.body-background {
  background-image: var(--body-background);
}

.root {
  display: flex;
}

::selection {
  background-color: var(--color-primary-1);
  color: var(--color-primary-4);
}

.u-center-text {
  text-align: center;
}

.u-margin-bottom-small {
  margin-bottom: 1.4rem;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-bottom-small {
    margin-bottom: 0.7rem;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 3.5rem;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-bottom-medium {
    margin-bottom: 1.75rem;
  }
}

.u-margin-bottom-large {
  margin-bottom: 7rem;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-bottom-large {
    margin-bottom: 3.5rem;
  }
}

.u-margin-bottom-huge {
  margin-bottom: 8.4rem;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-bottom-huge {
    margin-bottom: 4.2rem;
  }
}

.u-margin-top-small {
  margin-top: 1.4rem !important;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-top-small {
    margin-top: 1.4rem !important;
  }
}

.u-margin-top-medium {
  margin-top: 3.5rem !important;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-top-medium {
    margin-top: 1.75rem !important;
  }
}

.u-margin-top-large {
  margin-top: 7rem !important;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-top-large {
    margin-top: 3.5rem !important;
  }
}

.u-margin-top-huge {
  margin-top: 8.4rem !important;
}
@media only screen and (max-width: 52.6875em) {
  .u-margin-top-huge {
    margin-top: 4.2rem !important;
  }
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.text-align-center {
  text-align: center !important;
}

.flex {
  display: flex !important;
}

.align-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.unclickable {
  pointer-events: none !important;
  overflow: hidden !important;
}
.unclickable .navbar__dropdown {
  height: 0 !important;
}
.unclickable .navbar__button {
  box-shadow: none;
}
.unclickable .navbar__item {
  box-shadow: none;
}

.transparent {
  background-color: transparent !important;
  background-image: none !important;
  border: none !important;
  box-shadow: none !important;
}

.overflow--hidden {
  overflow: hidden !important;
}
@media only screen and (max-width: 68.75em) {
  .overflow--hidden {
    overflow: visible !important;
  }
}
.overflow--hidden:not(.navbar--active, .navbar--isAnimating) {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.sliding {
  transition: none !important;
}

.transition-none {
  transition: none !important;
}

.visible {
  visibility: visible !important;
}

.hidden, .sliding {
  visibility: hidden !important;
  opacity: 0 !important;
}

.full-opacity, .visible {
  opacity: 1 !important;
}

.full-screen {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100vw !important;
  height: 87.5vh !important;
  z-index: 10000 !important;
  border: none !important;
  border-radius: 0 !important;
  object-fit: contain !important;
  object-position: center !important;
  transition: none !important;
}

.carousel-transition {
  transition: transform 0.25s ease !important;
}

.d-none {
  display: none !important;
}

.z-index-navbar {
  z-index: 5 !important;
}
@media only screen and (max-width: 68.75em) {
  .z-index-navbar {
    z-index: 7 !important;
  }
}

.z-index-content {
  z-index: 6 !important;
}

.z-index-highest {
  z-index: 105 !important;
}

.scale-1 {
  transform: scale(1) !important;
}

.transform-none, .full-screen {
  transform: translate3d(0, 0, 0) !important;
}

.transform-removed {
  transform: none !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-1 {
  margin-top: 0;
}

.margin-top-2 {
  margin-top: 2.8rem !important;
}

.margin-left-3 {
  margin-left: 4.2rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-1 {
  margin-bottom: 1.4rem !important;
}

.margin-bottom-2 {
  margin-bottom: 2.8rem !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-1 {
  padding-top: 1.4rem !important;
}

.padding-top-2 {
  padding-top: 2.8rem !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-1 {
  padding-bottom: 1.4rem !important;
}

.padding-bottom-2 {
  padding-bottom: 2.8rem !important;
}

.fill-red {
  --opacity: calc(1 * .9);
  fill: rgba(var(--color-primary-red-rgb), var(--opacity)) !important;
}
.fill-red:hover {
  --opacity: 1;
}

.fill-primary-1 {
  fill: rgba(var(--color-primary-1-rgb), 1) !important;
}

.fill-primary-2 {
  fill: rgba(var(--color-primary-2-rgb), 1) !important;
}

.fill-primary-3 {
  fill: rgba(var(--color-primary-3-rgb), 1) !important;
}

.fill-primary-4 {
  fill: rgba(var(--color-primary-4-rgb), 1) !important;
}

.audio-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33.6rem, 1fr));
}
.audio-list__item {
  --padding-top-and-bottom: 0.7rem;
  --padding-right: 1.4rem;
  cursor: pointer;
  background: linear-gradient(to left, rgba(var(--color-primary-1-rgb), 1) 0%, rgba(var(--color-primary-4-rgb), 1) 5%, rgba(var(--color-primary-1-rgb), 1) 10%, rgba(var(--color-primary-1-rgb), 1) 100%);
  background-size: 200% auto;
  background-clip: text;
  color: transparent;
  animation: animate-skills infinite 10s;
  border-bottom-width: 0.14rem;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, rgba(var(--color-primary-4-rgb), 0.1) 0%, rgba(var(--color-primary-1-rgb), 1) 50%, rgba(var(--color-primary-1-rgb), 1) 50%, rgba(var(--color-primary-4-rgb), 0.1) 100%);
  border-image-slice: 1;
  text-decoration: underline;
  width: max-content;
  padding: var(--padding-top-and-bottom) var(--padding-right) var(--padding-top-and-bottom) 0;
}
@media only screen and (max-width: 58.5625em) {
  .audio-list__item {
    --padding-top-and-bottom: 1.4rem * .25;
    --padding-right: 1.4rem * .5;
    width: auto;
  }
}
.audio-list__item > span:nth-of-type(2) {
  padding-left: 0.7rem;
}
.audio-list__item:hover {
  color: var(--color-primary-1);
  text-decoration: none;
  border-image: none;
}

.audio-player__toggler svg, .audio-player__controls svg {
  transition: opacity 0.5s ease;
  cursor: pointer;
  fill: rgba(var(--svg-fill-color), 1);
}
.audio-player__toggler svg:hover, .audio-player__controls svg:hover {
  fill: rgba(var(--svg-fill-color), 0.75);
}
.audio-player__toggler svg:active, .audio-player__controls svg:active, .audio-player__toggler svg:focus, .audio-player__controls svg:focus {
  fill: rgba(var(--svg-active-color), 1);
}

.audio-player {
  transform: translateY(100%);
  transition: transform 0.5s ease, opacity 0.25s ease;
}

.audio-player {
  --background-color: rgba(var(--color-primary-2-rgb), 1);
  --color: var(--color-primary-4);
  --svg-fill-color: var(--color-primary-4-rgb);
  --svg-active-color: var(--color-primary-3-rgb);
  --svg-size: 4.272625rem;
  --toggler-padding: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  color: var(--color);
  background-color: var(--background-color);
  z-index: 10;
}
.audio-player__checkbox-label {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate3d(-50%, -50%, 0);
}
.audio-player__content {
  max-width: 600px;
  width: 100%;
  text-align: center;
}
.audio-player__controls {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;
  padding: 1.4rem 0;
}
.audio-player__controls svg:not(.audio-player__restart, .audio-player__backward, .audio-player__forward) {
  width: var(--svg-size);
  height: var(--svg-size);
}
.audio-player__controls > div {
  position: relative;
  width: var(--svg-size);
  height: var(--svg-size);
}
.audio-player__controls > div > svg {
  position: absolute;
  left: 0;
  top: 0;
}
.audio-player__details {
  font-size: 2.1875rem;
  padding: 1.4rem;
}
.audio-player__details-time {
  margin-left: 1.4rem;
}
.audio-player__progress {
  cursor: pointer;
  background-color: rgba(var(--svg-fill-color), 0.2);
  width: 100%;
  height: 2.1875rem;
}
.audio-player__progress > div {
  align-self: flex-start;
  background-color: var(--color);
  height: 100%;
}
.audio-player__backward, .audio-player__forward {
  width: calc(var(--svg-size) * 1.090838207);
  height: calc(var(--svg-size) * 1.090838207);
}
.audio-player__toggler {
  position: fixed;
  bottom: 0;
  right: 0;
  height: calc(100% + var(--svg-size) + var(--toggler-padding));
  background-color: var(--background-color);
  z-index: -1;
  border-radius: 1.4rem;
}
.audio-player__toggler svg {
  transition: transform 0.5s ease;
  transform: rotate(var(--audio-player-toggler-rotation-closed));
  width: calc(var(--svg-size) * 1.131266846);
  height: calc(var(--svg-size) * 1.131266846);
}
.audio-player__toggler svg.audio-player__toggler--open {
  transform: rotate(var(--audio-player-toggler-rotation-open));
}
.audio-player__restart {
  width: calc(var(--svg-size) * 0.75);
  height: calc(var(--svg-size) * 0.75);
}

